import React from 'react';
import logo from './logo.svg';
import './App.css';
import LandingComponent from './pages/LandingComponent';

function App() {
  return (
    <div className="Fitro-Coming-Soon">
      <LandingComponent />
    </div>
  );
}

export default App;
