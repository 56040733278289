import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ParallaxProvider } from 'react-scroll-parallax';
import MainWrapper, { MainContentSection, NormalClockWrapper } from '../common/ui/landing.style';
import BG from '../common/static/bg.png';
import "../styles/NormalClock.css";
import { Container } from '../common/Container/Container';
import NormalClock from '../common/NormalClock/NormalClock.js';
import { Fade } from "react-awesome-reveal";



const newDate = new Date(2025, 0, 10);
const deadline:Date = new Date(Date.parse(newDate.toISOString()) +  24 * 60 * 60 * 1000);
console.log(deadline);
// const deadline = new Date();

const LandingComponent = () => {
    return (
        <ParallaxProvider> 
            <MainWrapper
            style={{
                backgroundImage: `url(${BG})`,
                backgroundColor: '#18192e',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            >
                <Container>
                    <MainContentSection>
                            <h2>
                            Something big is coming soon
                        </h2>    
                        <Fade>
                            <NormalClockWrapper>
                                <NormalClock countdown={deadline} />
                            </NormalClockWrapper>
                        </Fade>
                    </MainContentSection>
                </Container>
            </MainWrapper>
        </ParallaxProvider>
    );
};

export default LandingComponent;